<div fxLayout="row" fxLayoutAlign="center center" class="unauthorized">
    <div fxFlex="25">
        <mat-card class="sign-in" fxLayout="column" fxLayoutAlign="center center">
            <div fxFlexFill>
                <div fxLayout="row" fxLayoutAlign="center center" class="message">
                    <div>
                        <span >Unauthorized Access !!!</span>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div>
                        <button mat-raised-button color="primary" (click)="signOutAndRedirect()">
                            Login Again
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="login-hint">
                    <div>
                        <small>Try login with different account</small>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>