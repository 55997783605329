<mat-nav-list>
  <a mat-list-item routerLink="/" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item routerLink="/lastrun" (click)="onSidenavClose()">
    <mat-icon>assignment_ind</mat-icon> <span class="nav-caption">Last Run</span>
  </a>
  <a mat-list-item routerLink="/runduration" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon><span class="nav-caption">Run Duration</span>
  </a>
  <a mat-list-item routerLink="/slowesttests" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon><span class="nav-caption">Slowest Tests</span>
  </a>
  <mat-list-item [matMenuTriggerFor]="menu">
    <mat-icon>unfold_more</mat-icon>
    <a matline>Example</a>
  </mat-list-item>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onSidenavClose()">View profile</button>
    <button mat-menu-item (click)="onSidenavClose()">Add contact</button>
  </mat-menu>
</mat-nav-list>
