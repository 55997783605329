import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private _subscriptions :any[] = [];

  @Output() public sidenavToggle = new EventEmitter();

  authenticated: boolean;

  constructor(public router: Router, public auth: AuthService) { }
 
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe) ; 
  }

  ngOnInit(): void {
    this.onToggleSidenav();
    
    let sub2 = this.auth.user$.subscribe((user) =>{
      this.authenticated = (user!=null);
    });
    this._subscriptions.push(sub2);
  }

  onToggleSidenav() :void { 
    this.sidenavToggle.emit();
  }

  signout(){
    this.auth.AuthLogout().then( ()=>{
      this.router.navigateByUrl("");
    });
  }
}
