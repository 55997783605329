import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private auth: AuthService, public router: Router) {}

  ngOnInit(): void {
  }

  signOutAndRedirect(){
    this.auth.AuthLogout().then(()=>{
      this.router.navigateByUrl('login')
    });
  }
}
