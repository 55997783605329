import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Image } from '../model/image.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss']
})
export class GalleryViewComponent implements OnInit {
  images: any[];
  displayBasic:boolean = false;
  responsiveOptions2:any[] = [
      {
          breakpoint: '1500px',
          numVisible: 5
      },
      {
          breakpoint: '1024px',
          numVisible: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];

  imagePaths:string[] = ["Cypress.config() -- Get and set configuration options (failed).png","my-image.png"];

  constructor(private angularFireStorage: AngularFireStorage) { }

  ngOnInit(): void {
    this.images = [];
    this.imagePaths.forEach(element => {
        this.angularFireStorage.ref(element).getDownloadURL().pipe(take(1)).subscribe((url:string) =>{
            let img = new Image();
            img.previewImageSrc = url;
            img.thumbnailImageSrc = url;
            this.images.push(img);
        });
    });
    
  }

}
