import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CypressIframeComponent } from './cypress-iframe/cypress-iframe.component';
import { DwDashboardComponent } from './dw-dashboard/dw-dashboard.component';
import { LastRunComponent } from './last-run/last-run.component';
import { MmUserGuard } from './mm-user.guard';
import { RunDurationComponent } from './run-duration/run-duration.component';
import { SigninComponent } from './signin/signin.component';
import { SlowestTestsComponent } from './slowest-tests/slowest-tests.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: SigninComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'dashboard', component: CypressIframeComponent, canActivate: [AuthGuard, MmUserGuard] },
  { path: 'dwdash', component: DwDashboardComponent, canActivate: [AuthGuard, MmUserGuard]  },
  { path: 'lastrun', component: LastRunComponent, canActivate: [AuthGuard, MmUserGuard]  },
  { path: 'runduration', component: RunDurationComponent, canActivate: [AuthGuard, MmUserGuard]  },
  { path: 'slowesttests', component: SlowestTestsComponent, canActivate: [AuthGuard, MmUserGuard]  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
