<div class="card">
    <h5>Average run duration over time</h5>
    <p-chart type="line" [data]="lineStylesData" [options]="basicOptions" ></p-chart>
</div>

<div class="card">
    <p-table [value]="nodes" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th>Week Beginning</th>
                <th>Average Runtime</th>
                <th>Typical Concurrency</th>
                <th>Time Saved From Parallelization</th>
                <th>Total Runs</th>
                <th>Failure Rate</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-node>
            <tr>
                <td>{{node.timeSeriesPointStartDatetime}}</td>
                <td>{{node.averageRuntime}}</td>
                <td>{{node.medianConcurrency}}</td>
                <td>{{node.parallelizationSavings}}</td>
                <td>{{node.totalRuns}}</td>
                <td><p-progressBar [value]="node.failureRate"></p-progressBar></td>
            </tr>
        </ng-template>
    </p-table>
</div>