<div fxLayout="row" fxLayoutAlign="center center" class="navigation">
    <div fxFlex="20" fxHide.xs></div>
    <div fxFlex="30" class="header-link" fxLayout="row" fxLayoutAlign="center center" routerLink="/dashboard"  queryParamsHandling="preserve" [ngClass]="{'selected' : currentURL === '/dashboard'}">
        WebDash
    </div>
    <div fxFlex="30" class="header-link" fxLayout="row" fxLayoutAlign="center center" routerLink="/dwdash" queryParamsHandling="preserve" [ngClass]="{'selected' : currentURL === '/dwdash'}">
        DWDash
    </div>
    <div fxFlex="20" fxHide.xs></div>
</div>
<div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs *ngIf="false">
    <div fxLayout="row" fxLayoutGap="15px" class="navigation-items">
        <div>
            <a routerLink="/lastrun">Last Run</a>
        </div>
        <div>
            <a routerLink="/runduration">Run Duration</a>
        </div>
        <div>
            <a routerLink="/slowesttests">Slowest Tests</a>
        </div>
    </div>
</div>