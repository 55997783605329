import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from './service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MmUserGuard implements CanActivate {
 
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.auth.user$.pipe(
        take(1),
        map(user => this.isMindMilieuUser(user)), // <-- map to boolean
        tap(allowed => {
          if (!allowed) {
            //console.log('access denied');
            //this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
            this.router.navigate(['unauthorized']);
          }
      }));
  }

  private isMindMilieuUser(user: firebase.default.User) :boolean{
    if(user!=null && user.email!=null){
      let email = user.email;
      var nameParts = email.split("@");
      var domain = nameParts.length==2 ? nameParts[1] : null;
      if(domain && domain.toLocaleLowerCase() === 'mindmilieu.com'){
        return true;
      }
    } 
    return false;
  }
  
}
