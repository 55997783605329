<div fxLayout="row" fxLayoutAlign="center center" class="signin-section">
    <div fxFlex="100">
        <mat-card class="sign-in" fxLayout="column" fxLayoutAlign="center center">
            <div fxFlexFill >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-raised-button color="primary" (click)="signin()" *ngIf="false">
                        Log in with Google
                    </button>
                    <img src="../../assets/google-sign-in2.png" class="logo" (click)="signin()">
                </div>
            </div>
        </mat-card>
    </div>
</div>
