import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input()
  videoURL:string;

  @Input()
  posterURL:string;

  @ViewChild("myVideo", { static: false }) videoplayer: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

}
