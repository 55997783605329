<p-galleria [(value)]="images" [(visible)]="displayBasic" [responsiveOptions]="responsiveOptions2" [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
    [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000"> 
    <ng-template pTemplate="item" let-item>
        <img [src]="item.previewImageSrc" style="width: 100%; display: block;"/>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item.thumbnailImageSrc" style="width: 20%; height: 20%; display: block;"/>
        </div>
    </ng-template>
</p-galleria>
<button pButton type="button" icon="pi pi-external-link" label="Show" (click)="displayBasic = true"></button>
<div *ngIf="false" class="p-grid" style="max-width: 400px;">
    <div *ngFor="let image of images; let index = index;" class="p-col-3" key="index">
        <img [src]="image.thumbnailImageSrc" [alt]="image.alt" style="cursor: pointer" (click)="imageClick(index)"/>
    </div>
</div>