import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dw-dashboard',
  templateUrl: './dw-dashboard.component.html',
  styleUrls: ['./dw-dashboard.component.scss']
})
export class DwDashboardComponent implements OnInit {

  url: string = "/assets/DatawarehouseHealth.html";
  dynamicHeight: string = "800";

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        // console.log(params); 

        if(params.h){
          this.dynamicHeight = params.h;
          // console.log(this.dynamicHeight);
        }
      }
    );
  }

}
