import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { ChartModule } from 'primeng/chart';
import { GalleriaModule } from 'primeng/galleria'
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';

import { LastRunComponent } from './last-run/last-run.component';
import { RunDurationComponent } from './run-duration/run-duration.component';
import { SlowestTestsComponent } from './slowest-tests/slowest-tests.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { environment } from 'src/environments/environment';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { CypressIframeComponent, SafePipe } from './cypress-iframe/cypress-iframe.component';
import { DwDashboardComponent } from './dw-dashboard/dw-dashboard.component';
import { SigninComponent } from './signin/signin.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { HeaderActionsComponent } from './header-actions/header-actions.component';

@NgModule({
  declarations: [
    AppComponent,
    LastRunComponent,
    RunDurationComponent,
    SlowestTestsComponent,
    VideoPlayerComponent,
    GalleryViewComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavListComponent,
    CypressIframeComponent,
    SafePipe,
    DwDashboardComponent,
    SigninComponent,
    UnauthorizedComponent,
    HeaderActionsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ChartModule,
    GalleriaModule,
    ButtonModule,
    TableModule,
    ProgressBarModule,
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatCardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
