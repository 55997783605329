import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from '../model/app-config.model';
import { AppConfigService } from '../service/app-config.service';
import * as filters from '../../mockdata/runduration/filters.json';
import * as data from '../../mockdata/runduration/stats-box-table.json';

@Component({
  selector: 'app-run-duration',
  templateUrl: './run-duration.component.html',
  styleUrls: ['./run-duration.component.scss']
})
export class RunDurationComponent implements OnInit {

  lineStylesData: any;

  nodes:[];

  basicOptions: any;

  subscription: Subscription;

  config: AppConfig;

  constructor(private configService: AppConfigService) { }

  ngOnInit(): void {
    console.log(filters['default']['data']);
    console.log(data['default']['data']);
    
    this.prepareLinesDataSample();
    this.prepareLinesData(data['default']['data']['metrics']['projectRunDurationsOverTime']);

    this.config = this.configService.config;
    this.updateChartOptions();
    this.subscription = this.configService.configUpdate$.subscribe(config => {
      this.config = config;
      this.updateChartOptions();
    });
  }

  prepareLinesDataSample(){
    this.lineStylesData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: true,
          borderColor: '#42A5F5',
          tension: 0.0
        },
        {
          label: 'Second Dataset',
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderDash: [5, 5],
          borderColor: '#66BB6A'
        },
        {
          label: 'Third Dataset',
          data: [12, 51, 62, 33, 21, 62, 45],
          fill: true,
          borderColor: '#FFA726',
          backgroundColor: 'rgba(255,167,38,0.2)'
        }
      ]
    };
  }

  prepareLinesData(projectRunDurationsOverTime){
    const labels = [];
    const values = [];
    projectRunDurationsOverTime.nodes.forEach(node => {
      labels.push(node.timeSeriesPointStartDatetime);
      values.push(Math.round(node.averageRuntime/60));
      node.failureRate = Math.round(node.failureRate);
    });
    this.nodes = projectRunDurationsOverTime.nodes;
    this.lineStylesData = {
      labels: labels,
      datasets: [
        {
          label: 'Average run duration over time',
          data: values,
          fill: true,
          borderColor: '#42A5F5',
          tension: 0.0
        },
      ]
    };
  }

  updateChartOptions() {
    if (this.config.dark)
      this.applyDarkTheme();
    else
      this.applyLightTheme();
  }

  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }]
      }
    };


  }

  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }]
      }
    };


  }

}
