import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-cypress-iframe',
  templateUrl: './cypress-iframe.component.html',
  styleUrls: ['./cypress-iframe.component.scss']
})
export class CypressIframeComponent implements OnInit {

  projectId: string = "59yhjh"; // 7s5okt for real-world-app
  prefixUrl = 'https://dashboard.cypress.io/projects';
  suffixUrl = 'runs';
  // url: string = "https://www.youtube.com/embed/CD-E-LDc384";
  url: string = `${this.prefixUrl}/${this.projectId}/${this.suffixUrl}`;
  dynamicHeight: string = "800";
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.url = `${this.prefixUrl}/${this.projectId}/${this.suffixUrl}`;
    this.route.queryParams.subscribe(params => {
      // console.log(params); 

      if(params.h){
        this.dynamicHeight = params.h;
        // console.log(this.dynamicHeight);
      }
      if(params.pid){
        this.url = `${this.prefixUrl}/${params.pid}/${this.suffixUrl}`;
        // console.log(this.dynamicHeight);
      }
    }
  );
  }

}
