import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss']
})
export class HeaderActionsComponent implements OnInit, OnDestroy {
  private _subscriptions :any[] = [];
  currentURL:string;

  constructor(public router: Router) {
    let sub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let currentUrl = event.url;
      if(currentUrl.indexOf("?") > -1){
        currentUrl = currentUrl.substring(0,currentUrl.indexOf("?"));
      }
      console.log(currentUrl);
      this.currentURL = currentUrl;
    });
    this._subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe) ; 
  }
  
  ngOnInit(): void {}

}
