import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<firebase.default.User>;

  constructor(private afAuth: AngularFireAuth) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user: firebase.default.User) => {
        // console.log(user);
        return of(user);
      }));
  }

  GoogleAuth() {
    return this.AuthLogin(new firebase.default.auth.GoogleAuthProvider());
  }

  isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(
      take(1),
      map(user => !!user), // <-- map to boolean
      tap(loggedIn => {
        return loggedIn;
      }));      
  }

  // Auth logic to run auth providers
  AuthLogin(provider): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.signInWithPopup(provider)
      .then((result: firebase.default.auth.UserCredential) => {
        console.log('You have been successfully logged in!')
        return result;
      }).catch((error) => {
        console.log(error)
        return null;
      })
  }

  AuthLogout(): Promise<void> {
    return this.afAuth.signOut()

      .then(function () {
        console.log('Signout Succesfull')
      }, function (error) {
        console.log('Signout Failed')
      });
  }
}
