import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from '../model/app-config.model';
import { AppConfigService } from '../service/app-config.service';


@Component({
  selector: 'app-last-run',
  templateUrl: './last-run.component.html',
  styleUrls: ['./last-run.component.scss']
})
export class LastRunComponent implements OnInit {

  stackedData: any;

  stackedOptions: any;
    
  subscription: Subscription;

  config: AppConfig;

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {
    this.stackedData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
          type: 'bar',
          label: 'Dataset 1',
          backgroundColor: '#42A5F5',
          data: [
              50,
              25,
              12,
              48,
              90,
              76,
              42
          ]
      }, {
          type: 'bar',
          label: 'Dataset 2',
          backgroundColor: '#66BB6A',
          data: [
              21,
              84,
              24,
              75,
              37,
              65,
              34
          ]
      }, {
          type: 'bar',
          label: 'Dataset 3',
          backgroundColor: '#FFA726',
          data: [
              41,
              52,
              24,
              74,
              23,
              21,
              32
          ]
      }]
  };

  this.stackedOptions = {
      tooltips: {
          mode: 'index',
          intersect: false
      },
      responsive: true,
      scales: {
          xAxes: [{
              stacked: true,
          }],
          yAxes: [{
              stacked: true
          }]
      }
  };
  
  this.config = this.configService.config;
  this.updateChartOptions();
  this.subscription = this.configService.configUpdate$.subscribe(config => {
      this.config = config;
      this.updateChartOptions();
  });
  }

  updateChartOptions() {
    if (this.config.dark) 
        this.applyDarkTheme();
    else
        this.applyLightTheme();
  }

  applyDarkTheme() {
    
    this.stackedOptions.scales.xAxes[0].ticks = {
        fontColor: '#ebedef'
    };
    this.stackedOptions.scales.xAxes[0].gridLines = {
        color: 'rgba(255,255,255,0.2)'
    };
    this.stackedOptions.scales.yAxes[0].ticks = {
        fontColor: '#ebedef'
    };
    this.stackedOptions.scales.yAxes[0].gridLines = {
        color: 'rgba(255,255,255,0.2)'
    };
    this.stackedOptions.legend = {
        labels:  {
            fontColor: '#ebedef'
        }
    };
    this.stackedOptions = {...this.stackedOptions};
  }

  applyLightTheme() {
    
    this.stackedOptions.scales.xAxes[0].ticks = {
        fontColor: '#495057'
    };
    this.stackedOptions.scales.xAxes[0].gridLines = {
        color: '#ebedef'
    };
    this.stackedOptions.scales.yAxes[0].ticks = {
        fontColor: '#495057'
    };
    this.stackedOptions.scales.yAxes[0].gridLines = {
        color: '#ebedef'
    };
    this.stackedOptions.legend = {
        labels:  {
            fontColor: '#495057'
        }
    };
    this.stackedOptions = {...this.stackedOptions};
  }
}
